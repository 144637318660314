<template>

	<div class="px-2 sm:px-6 lg:px-4 text-t3 dark:text-t3-nightText ">
		<div class="justify-between md:flex items-center">
			<h2 class="text-2xl font-bold sm:text-3xl dark:text-white">
				Assessments
			</h2>
			<div class=" space-x-2 flex">
			<router-link v-if="scopeCheck('show_calendar_view')" :to="{name: 'AssessmentCalendar'}"><button class=" font-medium  border-t3 border p-3 text-t3 flex rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary" >
				<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
					<path stroke-linecap="round" stroke-linejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
				</svg>
				Calendar</button></router-link>
			<router-link v-if="scopeCheck('create_booking')" :to="{name: 'BookNew'}"><button class=" font-medium bg-t3 p-3 text-white rounded-md hover:text-white hover:bg-t3-secondary" >New Booking</button></router-link>

			</div>
		</div>
		

    <router-view @updateAssessments="updateAssessments" @nextPage="nextPage" @lastPage="lastPage"  @firstPage="firstPage"  @previousPage="previousPage" @refreshPage="refreshPage" :page="page" :numberOfPages="numberOfPages" :loading="loading"></router-view>
	</div>


</template>

<style scoped>

</style>

<script>

import {mapActions}  from 'vuex'


	export default {
		
		data() {
			return {
				page:1,
				numberOfPages:12,
				loading:false,
			}
		},

		methods: {
						...mapActions({
				getassessments: 'Assessments/getAssessmentsPage',
				getcustomers: 'Users/getAllCustomers',
				}),
				
			updateAssessments(curr){
				this.page=1
				this.loading =true
				let curro = curr.curr
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
				this.getassessments({pageid:this.page,number:this.numberOfPages, stat:curro, filterString:curr.filterString}).then(()=>{
					this.loading=false
				})
			},
			firstPage(curr){
				this.page= 1
				this.loading =true
				let curro = curr.curr
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
				this.getassessments({pageid:this.page,number:this.numberOfPages, stat:curro, filterString:curr.filterString}).then(()=>{
					this.loading=false
				})
			},
			nextPage(curr){
				this.page++
				this.loading =true
				let curro = curr.curr
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
				this.getassessments({pageid:this.page,number:this.numberOfPages, stat:curro, filterString:curr.filterString}).then(()=>{
					this.loading=false
				})
			},
			lastPage(curr,count){
				this.page=count
				this.loading =true
				let curro = curr.curr
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
				this.getassessments({pageid:this.page,number:this.numberOfPages, stat:curro, filterString:curr.filterString}).then(()=>{
					this.loading=false
				})
			},
			previousPage(curr){
				this.page--
				this.loading =true
				let curro = curr.curr
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
				this.getassessments({pageid:this.page,number:this.numberOfPages, stat:curro, filterString:curr.filterString}).then(()=>{
					this.loading=false
				})
			},
			refreshPage(curr, num){
				this.page=1
				this.numberOfPages= num
				this.loading =true
				let curro = curr.curr
			if(curro === "completed"){
			curro = "completed&status=sealed"
			}
				this.getassessments({pageid:this.page,number:this.numberOfPages, stat:curro, filterString:curr.filterString}).then(()=>{
					this.loading=false
				})
			},
			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				
				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}	
		},
		components: {
			// Calendar
		},
		computed: {
			

		},

				
		}

</script>